// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toArray = (items: any): any[] =>
  Array.isArray(items) ? items : [items];

export function formatDate(input: string): string {
  const date = new Date(input);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

// eslint-disable-next-line arrow-body-style
export const getUrlExtension = (url: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return url?.split(/[#?]/)[0].split('.').pop()!.trim();
};

export const isPNG = (extension: string): boolean =>
  extension?.toLowerCase() === 'png';

export const priceToNumber = (number: string) =>
  parseFloat(number?.replace(/€/g, '').replace(',', '.'));

export const isProduction = (): boolean =>
  process.env.NODE_ENV === 'production';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debug = (message?: any, ...optionalParams: any[]): void => {
  // eslint-disable-next-line no-console
  if (!isProduction()) console.log(message, ...optionalParams);
};

export const removeBaseURL = (url: string): string =>
  url.replace(process.env.NEXT_PUBLIC_BACK_URL ?? '', '');

export const toKebabCase = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function getOrderStatusName(order: any) {
  if (order.canceledAt != null) {
    return 'Cancelado';
  } else if (order.fulfillmentStatus === 'UNFULFILLED') {
    return 'Pendiente';
  } else if (order.fulfillmentStatus === 'FULFILLED') {
    return 'Preparado';
  }
  return 'En Espera';
}

export function getOrderStatusClassName(order: any) {
  if (order.canceledAt != null) {
    return 'text-status-error';
  } else if (order.fulfillmentStatus === 'UNFULFILLED') {
    return 'text-text-secondary';
  } else if (order.fulfillmentStatus === 'FULFILLED') {
    return 'text-status-success';
  }
  return '';
}

export const getDeviceType = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return 'mobile';
  } else if (/iPad|Android|Tablet|Touch/i.test(navigator.userAgent)) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

export const ordenarEtiquetas = (arrayCadenas: string[]) => {
  const arbolDatos = {
    [toKebabCase('Productos Ibéricos')]: {
      peso: 1,
      [toKebabCase('Jamón Ibérico')]: {
        peso: 2,
      },
      [toKebabCase('Piezas de Jamón')]: {
        peso: 3,
      },
      [toKebabCase('loncheados')]: {
        peso: 3,
      },
      [toKebabCase('Quesos y Embutidos')]: {
        peso: 2,
      },
    },
    [toKebabCase('Productos de regalo')]: {
      peso: 2,
      [toKebabCase('Regalos cumpleaños')]: {
        peso: 3,
      },
      [toKebabCase('Regalos recién nacido')]: {
        peso: 3,
      },
    },
  };

  // const arbolDatos = new Map([
  //   { 'Productos Ibéricos': 1 },
  //   { 'Jamón Ibérico': 2 },
  //   { 'Piezas de Jamón': 3 },
  //   { loncheados: 3 },
  //   { 'Quesos y Embutidos': 2 },
  //   { 'Productos de regalo': 2 },
  //   { 'Regalos cumpleaños': 3 },
  //   { 'Regalos recién nacido': 3 },
  // ]);

  const obtenerPeso = (cadena: string) => {
    for (const categoria in arbolDatos) {
      if (categoria === cadena) {
        return arbolDatos[categoria as keyof typeof arbolDatos].peso;
      }

      for (const subcategoria in arbolDatos[
        categoria as keyof typeof arbolDatos
      ]) {
        if (subcategoria === cadena) {
          return arbolDatos[categoria as keyof typeof arbolDatos][subcategoria]
            .peso;
        }
      }
    }

    return 99;
  };

  const etiquetasOrdenadas = (
    Array.isArray(arrayCadenas)
      ? arrayCadenas.filter((cadena) => cadena !== undefined)
      : []
  ).sort((a, b) => obtenerPeso(toKebabCase(a)) - obtenerPeso(toKebabCase(b)));
  const etiquetasNoEncontradas = arrayCadenas?.filter(
    (etiqueta) => !etiquetasOrdenadas.includes(etiqueta)
  );
  return [etiquetasOrdenadas, etiquetasNoEncontradas];
};

export const getUrlFromMedia = (
  image: {
    field_media_image: {
      uri: {
        url: string
      }
    }
  }) => {
  if (image != null) {
    return (image.field_media_image?.uri.url)
  }
}

export const readingTime = (time: number, text: string) => time == 0 ? null : time > 0 ? time : readingTimeCalculator(text)

function readingTimeCalculator(t: string) {
  const text = t;
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time
}

export function getMeta(meta: any[], name: any, isProperty = false) {
  return meta?.find((item) => {
    const p = isProperty ? item.attributes?.property : item.attributes?.name;
    return item.tag == "meta" && p != undefined && p == name;
  })?.attributes.content;
}

export function isRelative(url: string) {
  return !new RegExp("^(?:[a-z]+:)?//", "i").test(url);
}

export function cleanHtmlTags(text) {
  const regex = /(<([^>]+)>)/gi;
  return text ? text.replace(regex, " ") : undefined;
}
